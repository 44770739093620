import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Img from 'gatsby-image';
import Section from 'components/section';

const Image = ({ image }) => (
  <Img
    fluid={image ? image.childImageSharp.fluid : {}}
    imgStyle={{ objectFit: 'cover' }}
    style={{ height: '100%', maxHeight: '100%' }}
  />
);

Image.propTypes = {
  image: PropTypes.object.isRequired,
};

const SportsAdvisory = ({ data }) => (
  <Layout>
    <Head pageTitle={data.sportsAdvisoryJson.title} />
    <Box spaced center>
      <Section className="sports-advisory">
        <div
          dangerouslySetInnerHTML={{
            __html: data.sportsAdvisoryJson.content.childMarkdownRemark.html,
          }}
        />
      </Section>
    </Box>
  </Layout>
);

SportsAdvisory.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SportsAdvisory;

export const query = graphql`
  query SportsAdvisoryQuery {
    sportsAdvisoryJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
